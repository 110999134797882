import "@shopify/polaris/build/esm/styles.css";
import {
  AppProvider,
  Page,
  LegacyCard,
  ResourceList,
  Avatar,
  Text,
  BlockStack,
} from "@shopify/polaris";
import { useEffect, useState } from "react";
import { fetchOrders } from "./api";

function App() {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchOrders().then((data) => setOrders(data));
  }, []);

  return (
    <AppProvider
      i18n={{
        Polaris: {
          ResourceList: {
            sortingLabel: "Sort by",
            defaultItemSingular: "order",
            defaultItemPlural: "orders",
            showing: "Showing {itemsCount} {resource}",
            Item: {
              viewItem: "View details for {itemName}",
            },
          },
          Common: {
            checkbox: "checkbox",
          },
        },
      }}
    >
      <Page>
        <BlockStack gap="500">
          <Text variant="headingLg" as="h6">
            Welcome to Monify Admin
          </Text>
          <LegacyCard>
            <ResourceList
              showHeader
              items={orders}
              renderItem={(item) => {
                const { id, url, name, location } = item;
                const media = <Avatar customer size="md" name={name} />;

                return (
                  <ResourceList.Item id={id} url={url} media={media}>
                    <Text variant="bodyMd" fontWeight="bold" as="h3">
                      {name}
                    </Text>
                    <div>{location}</div>
                  </ResourceList.Item>
                );
              }}
            />
          </LegacyCard>
        </BlockStack>
      </Page>
    </AppProvider>
  );
}

export default App;
