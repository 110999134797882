export const fetchOrders = async () => {
  try {
    const response = await fetch(
      "https://klby7xikk7.execute-api.us-east-1.amazonaws.com/orders?handle=thisisostad"
    );

    const { data = [], error } = await response.json();

    if (error) throw error;

    return data.map((item: any) => ({
      id: item.id,
      url: "#",
      name: item.customer.displayName,
      location:
        item.customer.addresses[0].city +
        ", " +
        item.customer.addresses[0].country,
    }));
  } catch (ex) {
    console.log(ex);

    return [];
  }
};
